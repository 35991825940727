<template>
  <div class="view-box">
    <div class="content-box">
      <router-view />
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue';
import { throttle } from 'lodash';
onMounted(() => {
  const content = document.querySelector(".content-box")
  function set_size() {
    document.body.style.height = `${content.offsetHeight}px`;
  }
  window.addEventListener('scroll', throttle(() => {
    content.style.transform = `translateY(${-scrollY}px)`
  }, 50))
  window.addEventListener('load', set_size)
  window.addEventListener('resize', set_size)
})
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
}

@font-face {
  font-family: 'UDDigiKyokashoN-B';
  src: url(./assets/font/UDDigiKyokashoN-B.ttc);
}

.jp {
  font-family: 'UD デジタル 教科書体 NK-B', 'UDDigiKyokashoN-B', sans-serif;
}

.view-box {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
}

.content-box {
  min-width: 100%;
  min-height: 100vh;
  position: relative;
  transform: translateY(0);
  transition: transform 300ms ease-in-out;
}
</style>
